<template>
  <div class="py-1">
<!--    <div v-if="material && material.audio_files && material.audio_files.length">-->
<!--      <div v-for="(a, aind) in material.audio_files" :key="`mat-au-${aind}`">{{ a.name }}</div>-->
<!--    </div>-->
    <label class="form-label mb-0" :for="'audio-'+material.id">
      <a class="btn-whole-add btn-whole-add-dashed">
        <PlusSvg class="mr-2 svg-icon-sm"/>{{ $t('add_audio') }}
      </a>
    </label>
    <input type="file"
           accept=".mp3,.wav"
           class="form-control d-none"
           :multiple="true"
           :id="'audio-'+material.id"
           @change="handleFileUploads"/>
  </div>
</template>

<script>

import PlusSvg from '@/assets/svg/plus.svg'
import { MaterialsService } from '@/services/api.service'

export default {
  name: "MaterialUploadAudio",
  components: {
    PlusSvg
  },
  props: {
    material: {}
  },
  data() {
    return {
      files: [],
      sizelimit: 10
    }
  },
  methods: {
    async handleFileUploads(e) {
      let files = e.target.files
      let i = 0
      for( i = 0; i < files.length; i++ ) {
        let format = /\.(mp3|wav)$/i
        let file = files[i]
        //   }
        if (! format.test(file.name ) ) {
          this.$error("У файла неверный формат")
          return
        }
        if(file.size > (this.sizelimit * 1024 * 1024)) {
          this.$error("Файл должен быть меньше "+this.sizelimit+"МБ")
          return
        }
        this.files.push(file)
        // this.$set(this.files, fileid, uploadedFiles[i])
      }
      if(this.files.length) {
        let formData = new FormData()
        // formData.append('files', this.files)
        this.files.forEach(file => {
          formData.append('files[]', file)
        })
        let res = await MaterialsService.addAudio(this.material.course_id, this.material.id, formData)
        let m = JSON.parse(JSON.stringify(this.material))
        m.audio_files = res.data.data
        this.$emit('updated', m, 'audio_files')
        this.files = []
      }
    },
  }
}
</script>

<style scoped>

</style>
