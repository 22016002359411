<template>
  <div class="py-1">
    <label class="form-label mb-0"
           :for="'presentation-'+material.id">
      <a class="btn-whole-add btn-whole-add-dashed">
        <PlusSvg class="mr-2 svg-icon-sm"/>{{ $t('add_presentation') }}
      </a>
    </label>
    <input type="file"
           accept=".pdf"
           class="form-control d-none"
           :id="'presentation-'+material.id"
           @change="handleFileUploads"/>
  </div>
</template>

<script>

import PlusSvg from '@/assets/svg/plus.svg'
import { MaterialsService } from '@/services/api.service'

export default {
  name: "MaterialUploadPresentation",
  components: {
    PlusSvg
  },
  props: {
    material: {}
  },
  data() {
    return {
      files: []  ,
      sizelimit: 5
    }
  },
  methods: {
    async handleFileUploads(e) {
      let files = e.target.files
      let i = 0
      for( i = 0; i < files.length; i++ ) {
        let format = /\.(pdf)$/i
        //   }
        if (! format.test(files[i].name ) ) {
          this.$error("У файла неверный формат")
          return
        }
        if(files[i].size > (this.sizelimit * 1024 * 1024)) {
          this.$error("Файл должен быть меньше "+this.sizelimit+"МБ")
          return
        }
        this.files.push(files[i])
        // this.$set(this.files, fileid, uploadedFiles[i])
      }
      if(this.files.length) {
        let formData = new FormData()
        this.files.forEach(file => {
          formData.append('files[]', file)
        })
        let res = await MaterialsService.addPresentation(this.material.course_id, this.material.id, formData)
        let m = JSON.parse(JSON.stringify(this.material))
        m.presentation_files = res.data.data
        this.$emit('updated', m, 'presentation_files')
        this.files = []
      }
    },
  }
}
</script>

<style scoped>

</style>
