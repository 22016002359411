<template>
  <div class="material-page">
    <h1 id="page-title">
      <router-link v-if="course && canEdit" :to="roleLinkAppendix()+'/courses/'+course.id">
        <BackSvg class="mr-3"/>
      </router-link>
      <span v-if="canEdit">{{ $t('lesson_constructor') }}</span><span v-if="material && !canEdit">{{ material.title }}</span>
    </h1>
    <div v-if="material && (canEdit || methodistLimitedAccess)">
      <b-row>
        <b-col lg="8">
          <div id="scroll-fixer">
            <div v-if="['lesson','homework'].includes(slidemode)">
              <Slide v-if="slideSelected && !showmodal"
                     :course="course"
                     :material="material"
                     :canEdit="canEdit"
                     :passedslide="slideSelected"
                     :index="slideSelectedIndex"
                     @slidedeleted="slidedeleted"
              />
              <div v-if="!slideSelected" class="spacecard min-h-card d-flex justify-content-center align-items-center">
                {{ $t('slide_not_selected') }}
              </div>
              <div v-if="showmodal" class="spacecard">{{ $t('preview') }}</div>
            </div>
            <div v-if="slidemode === 'words' && words.length > 0" class="spacecard min-h-card">
              <WordTeaser v-for="(w, wind) in words"
                          :key="'word-'+w.id"
                          @delete="deleteWord(w, wind)"
                          :candelete="canEdit"
                          :word="w">
              </WordTeaser>
            </div>
            <div v-if="slidemode === 'cards' && cards.length" 
                 class="spacecard min-h-card pt-5">
              <b-row> 
                <b-col v-for="(c, cind) in cards"
                       cols="12"
                       :key="'card-'+c.id">
                  <WordCardTeaserConstructor
                      @delete="deleteCard(c, cind)"
                      :candelete="canEdit"
                      :canedit="canEdit"
                      mode="constructor"
                      :word="c">
                  </WordCardTeaserConstructor>
                </b-col>
              </b-row>
              
            </div>
            <div v-if="['cards'].includes(slidemode) && !cards.length"
                 class="spacecard min-h-card d-flex justify-content-center align-items-center">
              {{ $t('no_words_added') }}
            </div>
            <div v-if="slidemode === 'presentation'" class="spacecard min-h-card">
              <h2 v-if="slideSelected" class="slide-header border-bottom-dashed">
                {{ slideSelected.name }}
              </h2>
              <template v-if="slideSelected && slideSelected.type === 'image'">
                <div v-for="(p, pind) in slideSelected.path" :key="`slide-sel-${pind}`">
                  <hr v-if="pind > 0" class="my-4"/>
                  <img :src="p" alt=""/>
                </div>
              </template>
              <template v-if="slideSelected && slideSelected.type === 'audio'">
                <audio controls class="w-100" :key="`audi-${slideSelected.path}`">
                  <source :src="slideSelected.path" type="audio/mpeg">
                </audio>
              </template>
            </div>
            <div v-if="slidemode === 'words' && !words.length"
                 class="spacecard min-h-card d-flex justify-content-center align-items-center">
              {{ $t('no_words_added') }}
            </div>
          </div>
        </b-col>
        
        <b-col lg="4">
          <affix class="spacecard md mb-3 slides-holder mt-lg-0 mt-3"
                 relative-element-selector="#scroll-fixer"
                 :enabled="!mobile"
                 :offset="{ top: 15, bottom: 15 }">
            <div class="btns">
              <b-row @click="showMaterialsMenu" class="pointer">
                <b-col cols="auto">
                  <LetterIcon :obj="material"
                              :title="material.title"
                              :key="'add-'+key"
                              class="student-icon sm"/>
                </b-col>
                <b-col>
                  <div class="position-relative">
                    <div>{{ material.title }}</div>
                    <div class="text-muted small" v-if="material.level">
                      {{ material.level.code }}. {{ material.level.title }}
                    </div>
                  </div>
                </b-col>
                <b-col cols="auto">
                  <DownSvg class="down-svg"/>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col>
                  <a class="btn-themed btn-white w-100" @click="preview">{{ $t('preview') }}</a>
                </b-col>
                <b-col cols="auto" v-if="canEdit">
                  <a class="pointer btn-themed btn-white" @click="editMaterial"><PenSvg class="svg-icon-sm"/></a>
                </b-col>
              </b-row>

              <div class="tabs-navigator mt-4">
                <a v-for="(t, tind) in tabs"
                   :key="`tab-${tind}`"
                   class="text-center"
                   :class="{ active: slidemode === t.id }"
                   @click="changeSlideMode(t.id)" >
                  <component :is="t.icon"/>
                  <div class="tab-subtitle">
                    {{ $t(t.title) }}
                  </div>
                </a>
              </div>
              <div class="mt-3">
                <a class="btn-whole-add btn-whole-add-dashed"
                   v-if="['lesson','homework'].includes(slidemode) && canEdit"
                   @click="addslide">
                  <PlusSvg class="mr-2 svg-icon-sm"/>{{ $t('add_slide') }}
                </a>
                <AddCardWord @updateCards="updateCards"
                             v-if="['cards'].includes(slidemode) && canEdit"/>
                <AddWord @selected="selectedWord"
                         v-if="['words'].includes(slidemode) && canEdit"/>
                <div v-if="['presentation'].includes(slidemode)">
                  <MaterialUploadPresentation :material="material"
                                              v-if="canEdit"
                                              @updated="materialUpdated"
                                              class="mt-3"/>
                  <MaterialUploadAudio :material="material" v-if="canEdit" @updated="materialUpdated"/>
                  <div class="mt-3" v-if="(material.audio_files && material.audio_files.length) || (material.presentation_files && material.presentation_files.length)">
                    <SliderTeaser v-for="(slide,sid) in material.audio_files"
                                  :key="'slide-aud-'+sid"
                                  :class="{ active: slideSelected && (slide.path === slideSelected.path) }"
                                  :slide="{ title: slide.name }"
                                  icon="AudioSvg"
                                  :canEdit="canEdit"
                                  @selected="selectSlide(sid, slide,'audio')"
                                  @delete="deleteAudio(sid, slide)"/>
                    <SliderTeaser v-for="(slide,sid) in material.presentation_files"
                                  :key="'slide-pres-'+sid"
                                  :canEdit="canEdit"
                                  :class="{ active: slideSelected && (slide.path === slideSelected.path) }"
                                  :slide="{ title: slide.name }"
                                  icon="PdfSvg"
                                  @selected="selectSlide(sid, slide,'image')"
                                  @delete="deletePresentation(sid, slide)"/>
                  </div>
                </div>
              </div>

            </div>
            <div class="mt-3" v-if="['lesson','homework'].includes(slidemode) && currentSlides.length">
              <draggable v-model="slides[slidemode]" @end="draggedSlide" :options="{ disabled: !canEdit }">
                <SliderTeaser v-for="(slide,sid) in currentSlides"
                              :key="'slide-'+sid"
                              :canEdit="canEdit"
                              :class="{ active: slideSelected && (slide.id === slideSelected.id) }"
                              :slide="slide"
                              @selected="selectSlide(sid, slide)"
                              @delete="deleteslide(sid, slide.id)"/>
              </draggable>
            </div>
          </affix>
        </b-col>
      </b-row>
      <AddMaterialModal v-if="material"
                        :course="course"
                        :material="material"
                        :key="'add-'+key"
                        @updated="materialUpdated"/>
      <PresentationPreviewModal v-if="showmodal"
                                :material="material"
                                :homework="false"/>
      <AddCardModal @loadCards="loadCards" 
                    @updateCards="loadCards"/>
      <AssignCardToMaterialModal @updateCards="loadCards" 
                                 :cards="cards"/>
      <SelectMaterial :coursePassed="course" 
                      :materialPassed="material"
                      @selected="materialSelected"/>
    </div>
    <div v-if="material && !canEdit"></div>
  </div>
</template>

<script>

import { MaterialsService, SlidesService, LessonsService, VocabularyService, CardsService } from '@/services/api.service'
import BackSvg from '@/assets/svg/back.svg'
import PenSvg from '@/assets/svg/pen.svg'
import PlusSvg from '@/assets/svg/plus.svg'
import HomeSvg from '@/assets/svg/home2.svg'
import DownSvg from '@/assets/svg/menu2.svg'
import CardsSvg from '@/assets/svg/cards.svg'
import PresentationSvg from '@/assets/svg/presentation.svg'
import LessonSvg from '@/assets/svg/lesson.svg'
import WordsSvg from '@/assets/svg/words.svg'
import WordTeaser from '@/views/teasers/WordTeaser'
import WordCardTeaserConstructor from '@/views/teasers/WordCardTeaserConstructor'
import SliderTeaser from '@/views/teasers/SliderTeaser'
import PresentationPreviewModal from '@/views/parts/class/preview/PresentationPreviewModal'
import AddWord from '@/views/parts/class/AddWord'
import Slide from '@/views/pages/instructor/courses/Slide'
import EventBus from '@/services/event-bus'
import AddMaterialModal from '@/views/pages/instructor/courses/AddMaterialModal'
import LetterIcon from '@/views/parts/general/LetterIcon'
import SelectMaterial from '@/views/parts/class/SelectMaterial'
import draggable from 'vuedraggable'
import { Affix } from 'vue-affix'
import MaterialUploadPresentation from "../../../parts/material/MaterialUploadPresentation"
import MaterialUploadAudio from "../../../parts/material/MaterialUploadAudio"
import LinkChat from "../../../parts/class/LinkChat"
import AddCardWord from "../../../parts/class/AddCardWord"
import AddCardModal from "../../../parts/material/AddCardModal"
import WordCardsLessonPart from "../../../parts/class/WordCardsLessonPart"
import AssignCardToMaterialModal from "../../../parts/material/AssignCardToMaterialModal"

export default {
  components: {
    AssignCardToMaterialModal,
    WordCardsLessonPart,
    AddCardModal,
    AddCardWord,
    LinkChat,
    MaterialUploadAudio,
    MaterialUploadPresentation,
    PenSvg,
    PlusSvg,
    BackSvg,
    HomeSvg,
    DownSvg,
    PresentationSvg,
    LessonSvg,
    WordsSvg,
    CardsSvg,
    SliderTeaser,
    Slide,
    draggable,
    PresentationPreviewModal,
    AddMaterialModal,
    AddWord,
    LetterIcon,
    WordTeaser,
    WordCardTeaserConstructor,
    Affix,
    SelectMaterial
  },
  data() {
    return {
      material: null,
      course: null,
      changed: false,
      slides: {
        "lesson": [],
        "homework": []
      },
      showmodal: false,
      slideSelected: null,
      slideSelectedIndex: null,
      cid: this.$route.params.cid,
      mid: this.$route.params.mid,
      slidemode: 'lesson',
      wordsLoaded: false,
      cardsLoaded: false,
      mobile: false,
      words: [],
      cards: [],
      key: 1,
      tabs: [
        { id: 'lesson', icon: LessonSvg, title: 'lesson' },
        { id: 'homework', icon: HomeSvg, title: 'homework_short'},
        { id: 'words', icon: WordsSvg, title: 'words' },
        { id: 'presentation', icon: PresentationSvg, title: 'presentation_short' },
        { id: 'cards', icon: CardsSvg, title: 'cards' },
      ]
    }
  },
  props: {
  },
  mounted() {
    this.load()
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === "presentationpreviewmodal") {
        this.showmodal = false
      }
    })
    this.resized()
    window.addEventListener('resize', this.resized)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resized)
  },
  computed: {
    currentSlides() {
      return ['lesson','homework'].includes(this.slidemode) ? this.slides[this.slidemode] : []
    },
    canEdit() {
      let user = this.$store.state.user
      if(user.primary_role_id == 3) {
        return true
      }
      if(user.primary_role_id == 6) {
        return true
      }
      if(!this.course) {
        return false
      }
      return this.course.type == 'custom' &&
          (this.course.instructor_id == user.id
              || this.course.school_id == user.id)
    },
    methodistLimitedAccess() {
      let user = this.$store.state.user
      if(user.primary_role_id != 6 || !this.course) {
        return false
      }
      return this.course.instructor_id != user.id
    }
  },
  watch: {
    slidemode(val) {
      if(val === 'words' && !this.wordsLoaded) {
        VocabularyService.getWordsForMaterial(this.cid, this.mid).then(res => {
          this.wordsLoaded = true
          this.words = res.data.data
        })
      }
      if(val === 'cards' && !this.cardsLoaded) {
        this.loadCards()
      }
    },
    $route () {
      this.cid = this.$route.params.cid
      this.mid = this.$route.params.mid
      this.slidemode = 'lesson'
      this.slideSelectedIndex = null
      this.slideSelected = null
      this.wordsLoaded = false
      this.cardsLoaded = false
      this.load()
    }
  },
  methods:  {
    load() {
      if(this.canEdit) { // @ToDo: canEdit depends on course, but course is not loaded, so it is always false
        MaterialsService.get(this.cid, this.mid).then(res => {
          this.material = res.data.data
          this.course = this.material.course
          this.slides = this.material.slidesByTypes
          if(this.slides[this.slidemode].length > 0) {
            this.slideSelected = this.slides[this.slidemode][0]
          }
          this.breadcrumbs()
        })
      } else {
        LessonsService.getSlidesForMaterial(this.mid).then(res => {
          this.material = res.data.data.material
          this.course = this.material.course
          this.slides = res.data.data.slides
          if(this.slides[this.slidemode].length > 0) {
            this.slideSelected = this.slides[this.slidemode][0]
          }
          this.breadcrumbs()
        })
      }
    },
    editMaterial() {
      this.$bvModal.show('addmaterialmodal')
    },
    materialUpdated(material, type) {
      this.key = Math.random().toString().substr(2, 5)
      this.material = material
      this.breadcrumbs()
      this.$nextTick(() => {
        if(type == 'presentation_files' && this.material.presentation_files.length) {
          let obj = this.material.presentation_files[(this.material.presentation_files.length - 1)]
          obj.type = 'image'
          this.slideSelected = obj
        }
        if(type == 'audio_files' && this.material.audio_files.length) {
          let obj = this.material.audio_files[(this.material.audio_files.length - 1)]
          obj.type = 'audio'
          this.slideSelected = obj
        }
      })
    },
    addslide() {
      SlidesService.add(this.cid, this.mid, {
        title: "Слайд #"+(this.currentSlides.length + 1),
        type: this.slidemode
      }).then(res => {
        this.slides[this.slidemode].push(res.data.data)
        this.slideSelected = this.currentSlides[(this.currentSlides.length - 1)]
      })
    },
    deleteslide(index, id) {
      SlidesService.delete(this.cid, this.mid, id).then(() => {
        this.slides[this.slidemode].splice(index, 1)
        this.slideSelected = null
      })
    },
    loadCards() {
      CardsService.getWordCardsForMaterial(this.cid, this.mid).then(res => {
        this.cardsLoaded = true
        this.cards = res.data.data
      })
    },
    async deletePresentation(index, obj) {
      if(this.slidemode == 'presentation') {
        if(this.slideSelected && this.slideSelected.path == obj.path) this.slideSelected = null
      }
      await MaterialsService.deletePresentation(this.cid, this.mid, obj.path)
      this.material.presentation_files.splice(index, 1)
    },
    async deleteAudio(index, obj) {
      if(this.slidemode == 'presentation') {
        if(this.slideSelected && this.slideSelected.path == obj.path) this.slideSelected = null
      }
      await MaterialsService.deleteAudio(this.cid, this.mid, obj.path)
      this.material.audio_files.splice(index, 1)
    },
    selectSlide(index, slide, type = null) {
      this.slideSelectedIndex = index
      if(type) slide.type = type
      this.slideSelected = slide
    },
    slidedeleted() {
      this.slides[this.slidemode] = this.slides[this.slidemode].filter((obj) => {
        return obj.id !== this.slideSelected.id
      });
      this.slideSelected = null
    },
    draggedSlide() {
      MaterialsService.resort(this.cid, this.mid, {
        slides: this.slides[this.slidemode],
        type: this.slidemode
      }).then(res => {
        console.log(res)
      })
    },
    preview() {
      this.showmodal = true
      // setTimeout(() => {
      //     this.$bvModal.show('presentationpreviewmodal')
      // }, 50)
      this.$nextTick(() => {
        this.$bvModal.show('presentationpreviewmodal')
      })
    },
    breadcrumbs() {
      EventBus.$emit('breadcrumbs',[
        { to: '/courses', title: this.$t('courses') },
        { to: '/courses/'+this.cid, title: this.course.title },
        { to: '', title: this.material.title },
      ])
    },
    changeSlideMode(mode) {
      this.slidemode = mode
      if(['lesson','homework'].includes(mode) && this.slides[this.slidemode].length > 0) {
        this.slideSelected = this.slides[this.slidemode][0]
      } else {
        this.slideSelected = null
      }
    },
    selectedWord(word) {
      let params = {
        word: word.word,
        translation: word.translation_ru
      }
      VocabularyService.addWordToMaterial(this.cid, this.mid, params).then(res => {
        this.words = res.data.data
      })
    },
    deleteWord(word, index) {
      VocabularyService.deleteWordFromMaterial(this.cid, this.mid, word.id).then(() => {
        this.words.splice(index, 1)
      })
    },
    updateCards(cards) {
      this.cards = cards
    },
    deleteCard(card, index) {
      CardsService.deleteWordCardFromMaterial(this.cid, this.mid, card.id).then(() => {
        this.cards.splice(index, 1)
      })
    },
    resized() {
      this.mobile = window.innerWidth <= 992
    },
    showMaterialsMenu() {
      this.$bvModal.show('selectmaterialmodal')
    },
    materialSelected(m) {
      let name =  'Material'
      if(this.$store.state.user.primary_role_id == 4) {
        name = 'SchoolMaterial'
      }
      this.$router.push({ name: name, params: {
          cid: m.course_id,
          mid: m.id
        }
      })
    }
  },
  metaInfo() {
    return {
      title: this.$t('lesson_constructor'),
    }
  },
}
</script>

<style scoped lang="scss">
.material-page {
  .slides-holder {
    padding-left: 0;
    .btns {
      padding-left: 24px;
    }
    .btn-whole-add-dashed {
      height: 46px;
      line-height: 46px;
    }
  }
  .vue-affix {
    width: 380px;
  }
}
.tabs-navigator {
  display: flex;
  justify-content: space-between;
  a {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    border-bottom: 2px solid transparent;
    svg {
      stroke: $text-gray3;
      stroke-width: 2px;
      fill: $text-gray3;
    }
    &.active, &:hover {
      svg {
        stroke: $text-themed;
        fill: $text-themed;
      }
    }
  }
  a:first-of-type {
    padding-left: 12px;
  }
  //svg {
  //  height: 24px;
  //}
  .tab-subtitle {
    margin-top: 5px;
    font-size: 10px;
  }
}
//.down-svg {
//  opacity: 0.3;
//}
@media(max-width: 992px) {
  .material-page {
    .vue-affix {
      width: 100%;
    }
  }
}

@media(max-width: 768px) {
  .material-page {
    .vue-affix {
      width: 100%;
    }
    .slides-holder {
      .btns {
        padding-left: 16px;
      }
    }
  }
}
</style>


