<template>
  <div>
    <a class="btn-whole-add btn-whole-add-dashed mt-3"
       @click="assignCard">
      <PlusSvg class="mr-2 svg-icon-sm"/>{{ $t('add_card') }}
    </a>
    <a class="btn-whole-add btn-whole-add-dashed mt-3"
       @click="addCard">
      <PlusSvg class="mr-2 svg-icon-sm"/>{{ $t('create_card') }}
    </a>
  </div>
</template>

<script>

import PlusSvg from '@/assets/svg/plus.svg'
import { CardsService } from "@/services/api.service"

export default {
  name: "AddCardWord",
  components: {
    // WordTeaser,
    PlusSvg,
  },
  data() {
    return {
      showDropdown: false,
      q: '',
      foundWords: [],
      timer: null,
      cid: this.$route.params.cid,
      mid: this.$route.params.mid,
    }
  },
  props: {
  },
  watch: {
    q() {
      if(this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.search()
      }, 250)
    }
  },
  mounted() {},
  methods: {
    search() {
      if(!this.q) {
        return
      }
      CardsService.searchForCardWord(this.q, false).then(res => {
        this.foundWords = res.data.data.data
      })
    },
    initFocus() {
      this.showDropdown = true
      this.search()
    },
    clickoutside() {
      // this.showDropdown = false
      this.q = ""
    },
    async selectWord(word) {
      let res = await CardsService.addWordCardToMaterial(this.cid, this.mid, word.id)
      this.$emit('updateCards', res.data.data)
      this.q = ""
    },
    addCard() {
      this.$bvModal.show('addcardmodal')
    },
    assignCard() {
      this.$bvModal.show('assigncardtomaterialmodal')
    }
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">

.forChat {
  margin: 5px 0;
  input {
    height: 60px;
    border: none;
    background: #F8F9FB;

    &:focus {
      box-shadow: none;
    }
  }
}

.position-relative {
  position: relative;
  z-index: 100;
}

.space-dropdown {
  padding: 8px;
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  .add-own-version {
    padding: 16px 16px;
    display: block;
  }
}
</style>


