<template>
  <b-modal id="assigncardtomaterialmodal"
           size="lg"
           modal-class="modal-right-pane"
           @show="onShow"
           @hide="onHide">
    <template v-slot:modal-header="{ close }">
      <h2>{{ $t('add_card') }}</h2>
      <a class="pointer position-relative modal-close" @click="close()">
        <CloseSvg/>
      </a>
    </template>
    <input type="text"
           class="form-control form-control-white padded showplaceholder"
           v-model="q"
           :placeholder="$t('word')"/>
    <div class="position-relative pb-4">
      <div class="search-block mt-3"
           v-if="foundWords.length">
        <div v-for="(f, find) in foundWords"
                    :key="'found-word-'+find"
                    @click="selectWord(f)">
          <WordCardTeaserRow :word="f" 
                             :cards="cards" 
                             view="constructor"/>
<!--          <b-row class="align-items-center">-->
<!--            <b-col cols="auto">-->
<!--              <div class="checkbox mt-1">-->
<!--                <input type="checkbox"-->
<!--                       :checked="cards.find(x => x.id === f.id)"-->
<!--                       value="1"/>-->
<!--                <label></label>-->
<!--              </div>-->
<!--            </b-col>-->
<!--            <b-col cols="auto">-->
<!--              <img class="word-img" :src="f.image ? f.image : `/images/logo_h.png`" alt=""/>-->
<!--            </b-col>-->
<!--            <b-col>-->
<!--              <div class="pl-1 text-capitalize">-->
<!--                {{ f.word }}<span v-if="f.transcription" class="text-muted ml-3">[{{ f.transcription }}]</span>-->
<!--              </div>-->
<!--            </b-col>-->
<!--          </b-row>-->
        </div>
        <div class="word-searched add-own-version small text-muted"
             v-if="!foundWords.length">
          {{ $t('nothing_found') }}
        </div>
      </div>
    </div>
    <template v-slot:modal-footer="{ close }">
      <b-button @click="close"
                class="btn-themed btn-white">
        {{ $t('close') }}
      </b-button>
<!--      <b-button class="btn-themed"-->
<!--                :disabled="!valid"-->
<!--                @click="save">-->
<!--        {{ $t('save') }}-->
<!--      </b-button>-->
    </template>
  </b-modal>
</template>

<script>

import CloseSvg from '@/assets/svg/close.svg'
import { CardsService } from "../../../services/api.service"
import WordCardTeaserRow from "../../teasers/WordCardTeaserRow"
// import CheckboxGroup from "../general/form/CheckboxGroup"
// import {mapState} from "vuex"

export default {
  name: "AssignCardToMaterialModal",
  components: {
    WordCardTeaserRow,
    // CheckboxGroup,
    CloseSvg,
  },
  data() {
    return {
      showDropdown: false,
      q: '',
      foundWords: [],
      timer: null,
      cid: this.$route.params.cid,
      mid: this.$route.params.mid,
    }
  },
  props: {
    cards: {}
  },
  watch: {
    q() {
      if(this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.search()
      }, 250)
    }
  },
  mounted() {
    
  },
  methods: {
    search() {
      CardsService.searchForCardWord(this.q, false).then(res => {
        this.foundWords = res.data.data.data
      })
    },
    async selectWord(word) {
      let res;
      if(this.cards.find(x => x.id === word.id)) {
        res = await CardsService.deleteWordCardFromMaterial(this.cid, this.mid, word.id)  
      } else {
        res = await CardsService.addWordCardToMaterial(this.cid, this.mid, word.id)  
      }
      this.$emit('updateCards', res.data.data)
    },
    onShow() {
      this.search()
    },
    onHide() {
      
    }
  },
}
</script>

<style scoped lang="scss">

</style>
