<template>
  <b-modal id="addmaterialmodal"
           @show="onShow"
           modal-class="modal-right-pane">
    <template v-slot:modal-header="{ close }">
      <h2>{{ material ? $t('edit_material') : $t('add_material') }}</h2>
      <a class="pointer position-relative modal-close" @click="close()">
        <CloseSvg/>
      </a>
    </template>
    <h3>{{ $t('title') }}</h3>
    <InputGroup :label="$t('title')"
                class="default"
                type="text"
                v-model="form.title"/>
    <h3>{{ $t('level') }}</h3>
    <SelectGroup class="default"
                 v-model="form.level_id"
                 :translate="false"
                 :options="levels"/>
    <h3>{{ $t('topics') }}</h3>
    <div class="form-group">
      <multiselect v-model="form.topics"
                   id="id"
                   @tag="addTag"
                   :taggable="true"
                   :options="topics"
                   :multiple="true"
                   :hideSelected="true"
                   select-label=""
                   :placeholder="$t('select_topics')"
                   :closeOnSelect="false"
                   label="title"
                   trackBy="id">
      </multiselect>
    </div>
    <h3>{{ $t('bot') }}</h3>
    <SelectGroup class="default"
                 v-model="form.bot_id"
                 :translate="false"
                 :options="bots"/>
    <h3>{{ $t('photo') }}</h3>
    <p class="form-note">{{ $t('image_requirements') }}</p>
    <FileGroup accept="image/png,image/jpg,image/jpeg"
               v-model="image"
               :deletefile="true"
               @deleteFile="form.deletefile = true"
               fid="studentphoto"
               :label="$t('add_photo')"/>
    <template v-slot:modal-footer>
      <b-button v-if="material && canDelete"
                @click="destroy"
                :disabled="loading"
                class="btn-themed btn-white">
        <TrashSvg/>
      </b-button>
      <b-button class="btn-themed"
                :disabled="!valid || loading"
                @click="save">
        {{ $t('save') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import CloseSvg from '@/assets/svg/close.svg'
import InputGroup from "@/views/parts/general/form/InputGroup"
import SelectGroup from "@/views/parts/general/form/SelectGroup"
import FileGroup from "@/views/parts/general/form/FileGroup"
import { MaterialsService, DictionaryService, ChatService } from '@/services/api.service'
import TrashSvg from '@/assets/svg/trash2.svg'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    CloseSvg, InputGroup, SelectGroup, FileGroup, TrashSvg, Multiselect
  },
  data() {
    return {
      form: {
        title: "",
        level_id: "",
        bot_id: null,
        topics: []
      },
      levels: [],
      topics: [],
      image: null,
      loading: false,
        bots: []
    }
  },
  props: {
    course: {},
    material: {},
  },
  mounted() {
    this.load()
  },
  methods: {
    addTag (newTag) {
      const param = {
        title: newTag,
      }
      DictionaryService.add(param)
          .then(res => {
            this.form.topics.push(res.data.data)})
          .catch('ERROR')
    },
    onShow() {
      this.load()
    },
    async load() {
      if(this.$store.state.dictionary) {
        this.levels = this.$store.state.dictionary.levels
        this.topics = this.$store.state.dictionary.topics
      } else {
        const res = await DictionaryService.get()
        this.levels = res.data.data.levels
        this.topics = res.data.data.topics
      }
      const botsData = await ChatService.getChatBots()
      this.bots = botsData?.data.data
      if(this.material) {
        this.form = {
          title: this.material.title,
          level_id: this.material.level_id,
          topics: this.material.topics,
          bot_id: this.material.bot_id
        }
        this.image = this.material.image
      }
    },
    save() {
      let formData = new FormData()
      if(this.image && typeof this.image === 'object') {
        formData.append('image', this.image)
      }
      formData = this.appendFormdata(formData, this.form)
      this.loading = true
      if(this.material) {
        MaterialsService.update(this.course.id, this.material.id, formData).then(res => {
          this.loading = false
          this.$emit('updated',res.data.data)
          this.$bvModal.hide('addmaterialmodal')
        }).catch(() => this.loading = false)
      } else {
        MaterialsService.add(this.course.id, formData).then(res => {
          this.loading = false
          let name =  'Material'
          if(this.$store.state.user.primary_role_id == 4) {
            name = 'SchoolMaterial'
          }
          this.$router.push({ name: name, params: {
              cid: this.course.id,
              mid: res.data.data.id
            }
          })
        }).catch(() => this.loading = false)
      }
    },
    destroy() {
      this.$confirm(this.$t('are_you_sure_you_want_to_delete')).then(() => {
        this.loading = true
        MaterialsService.delete(this.course.id, this.material.id).then(() => {
          this.loading = false
          this.$success(this.$t('material_deleted'))
          let name =  'Courses'
          if(this.$store.state.user.primary_role_id == 4) {
            name = 'SchoolCourses'
          }
          this.$router.push({ name: name, params: { id: this.course.id } })
        }).catch(() => this.loading = false)
      })
    }
  },
  computed: {
    valid() {
      return this.form.title && this.form.level_id
    },
    canDelete() {
      let user = this.$store.state.user
      if(user.primary_role_id != 6) {
        return true
      }
      return this.course.type == 'custom' &&
          (this.course.instructor_id == user.id
              || this.course.school_id == user.id)
    }
  }
}
</script>

