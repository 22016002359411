<template>
  <b-modal id="addcardmodal"
           size="lg"
           modal-class="modal-right-pane"
           @show="onShow"
           @hide="onHide">
    <template v-slot:modal-header="{ close }">
      <h2>{{ $t('create_card') }}</h2>
      <a class="pointer position-relative modal-close" @click="close()">
        <CloseSvg/>
      </a>
    </template>
    <label>{{ $t('word') }}</label>
    <InputGroup :label="$t('word')"
                class="default"
                type="text"
                v-model="form.word"/>
    <label class="mt-4">{{ $t('transcription') }}</label>
    <InputGroup :label="$t('transcription')"
                class="default"
                type="text"
                v-model="form.transcription"/>
    <label class="mt-4">{{ $t('description') }}</label>
    <AutosizeTextArea :label="$t('description')"
                      class="form-control-white"
                      :placeholder="$t('description')"
                      :white="true"
                      :minH="50"
                      :maxH="200"
                      type="text"
                      v-model="form.description"      
    />
    <label class="mt-4">{{ $t('photo') }}</label>
    <FileGroup accept="image/png,image/jpg,image/jpeg"
               v-model="image"
               :deletefile="true"
               @deleteFile="form.deletefile = true"
               fid="studentphoto"
               :label="$t('add_photo')"/>
    <label class="mt-4">{{ $t('audio') }}</label>
    <FileGroup accept=".mp3,.wav"
               v-model="audio"
               :deletefile="true"
               @deleteFile="form.deletefile = true"
               fid="audio"
               :label="$t('add_audio')"/>
    <template v-slot:modal-footer>
      <b-button v-if="form.id"
                @click="deleteWordCard"
                class="btn-themed btn-white btn-outline-danger">
        {{ $t('delete') }}
      </b-button>
      <b-button class="btn-themed"
                :disabled="!valid"
                @click="save">
        {{ $t('save') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>

import CloseSvg from '@/assets/svg/close.svg'
import InputGroup from "@/views/parts/general/form/InputGroup"
import {CardsService} from "../../../services/api.service"
import FileGroup from "@/views/parts/general/form/FileGroup"
import {mapState} from "vuex"
import AutosizeTextArea from "../general/form/AutosizeTextArea"

export default {
  name: "AddCardModal",
  components: {
    AutosizeTextArea,
    CloseSvg,
    FileGroup,
    InputGroup
  },
  computed: {
    ...mapState({
      card: state => state.material.card,
    }),
    valid() {
      return this.form.word
    }
  },
  data() {
    return {
      form: {
        word: '',
        transcription: '',
        description: '',
        id: null
      },
      image: null,
      audio: null,
    }
  },
  methods: {
    async save() {
      let formData = new FormData()
      if(this.image && typeof this.image === 'object') {
        formData.append('image', this.image)
      }
      if(this.audio && typeof this.audio === 'object') {
        formData.append('audio', this.audio)
      }
      formData = this.appendFormdata(formData, this.form)
      try {
        await CardsService.createWordCard(formData)
        this.$toast(this, this.form.id ? "Карточка изменена" : "Карточка добавлена")
        this.$bvModal.hide('addcardmodal')
        this.$emit('updateCards')
      } catch (e) {
        this.$error(e.response.data.message)
      }
    },
    onShow() {
      if(this.card) {
        this.form.word = this.card.word
        this.form.transcription = this.card.transcription
        this.form.description = this.card.description
        this.form.id = this.card.id
      }
    },
    onHide() {
      this.$store.commit('setCard', null)
      this.form = {
        word: '',
        transcription: '',
        description: '',
        id: null
      }
      this.image = null
      this.audio = null
    },
    async deleteWordCard() {
      if(!this.form.id) return
      await CardsService.deleteWordCard(this.form.id)
      this.$emit('loadCards')
      this.$bvModal.hide('addcardmodal')
    }
  }
}
</script>

<style scoped>

</style>
